import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { DashboardReportData } from '../../@types/merchant'
import { Nullable } from '../../@types/nullable'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

export type BodyRequest = {
	limit?: number
	offset?: number
}

async function getDashboardReport(httpInstance: KyInstance, body: BodyRequest) {
	try {
		const response = await httpInstance.post('businesses/dashboard-report', {
			body: JSON.stringify(body),
		})
		return response.json<DashboardReportData>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useDashboardReport(body: BodyRequest) {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<DashboardReportData>, Error, Nullable<DashboardReportData>>({
		queryKey: ['report', body], // Include body in the query key to trigger re-fetching on changes
		queryFn: () => getDashboardReport(paymentServiceClient.httpInstance, body),
		enabled: !isLoading && isAuthenticated,
	})
}
