import { Card, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'

import { PurchaseWebsocketStatus } from '../../../@types/purchase'
import { COLOR } from '../../../layout/constants'
import { dateFormatter, timeFormatter } from '../../../utils/dates.ts'
import { formatCentsToCurrency } from '../../../utils/money-utils.ts'

type TransactionCardProps = {
	children: React.ReactNode
}
function TransactionCard({ children }: TransactionCardProps) {
	return (
		<Card
			sx={{
				background: '#FFFFFF',
				padding: '0.2rem 1rem 1rem',
				borderRadius: '16px',
				overflow: 'initial',
				mb: 4,
			}}
		>
			{children}
		</Card>
	)
}

type TransactionDescriptionItem = {
	label: string
	id: string
	value?: string
}

type TransactionDescriptionProps = {
	transaction: PurchaseWebsocketStatus
	children?: React.ReactNode
}

const getTransactionDescription = (transaction: PurchaseWebsocketStatus): TransactionDescriptionItem[] => {
	const date = new Date(transaction.timestamp)

	const transactionMapper: TransactionDescriptionItem[] = [
		{ label: 'Transaction Total', id: 'amount' },
		{ label: 'Transaction ID', id: 'publicId' },
		{ label: 'Business', id: 'businessName' },
		{ label: 'Account', id: 'customerAccountLastFour' },
		{ label: 'Date', id: 'date' },
		{ label: 'Time', id: 'time' },
	]

	return transactionMapper.map((item) => {
		switch (item.id) {
			case 'amount':
				item.value = formatCentsToCurrency(transaction.amount)
				break
			case 'publicId':
				item.value = transaction.publicId
				break
			case 'businessName':
				item.value = transaction.businessName
				break
			case 'customerAccountLastFour':
				item.value = transaction.customerAccountLastFour
				break
			case 'date':
				item.value = dateFormatter.format(date)
				break
			case 'time':
				item.value = timeFormatter.format(date)
				break
			default:
				break
		}
		return item
	})
}

export const TransactionDescription = ({ transaction, children }: TransactionDescriptionProps) => {
	const transactionDescriptionItems = getTransactionDescription(transaction)
	return (
		<TransactionCard>
			<Table
				sx={{ width: '100%', color: COLOR.primary, border: 'none' }}
				aria-label="simple table"
				size="small"
			>
				<TableHead>
					<TableRow sx={{ '& td, & th': { padding: '6px 0px', paddingLeft: 0, paddingRight: 0 } }}>
						<TableCell>
							<Typography
								sx={{
									fontSize: '13px',
									fontWeight: '700',
									lineHeight: '15.73px',
									textAlign: 'left',
									color: COLOR.primary,
								}}
							>
								Description
							</Typography>
						</TableCell>
						<TableCell>
							<Typography
								sx={{
									fontSize: '13px',
									fontWeight: '700',
									lineHeight: '15.73px',
									textAlign: 'left',
									color: COLOR.primary,
								}}
							>
								Information
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{transactionDescriptionItems.map((row) => (
						<TableRow
							key={row.id}
							sx={{ '& td, & th': { border: 0, padding: '6px 0px', paddingLeft: 0, paddingRight: 0 } }}
						>
							<TableCell
								component="th"
								scope="row"
							>
								<Typography
									sx={{
										fontSize: '13px',
										fontWeight: '700',
										lineHeight: '15.73px',
										textAlign: 'left',
										color: COLOR.primary,
									}}
								>
									{row.label}
								</Typography>
							</TableCell>
							<TableCell>
								<Typography
									sx={{
										fontSize: '13px',
										fontWeight: '400',
										lineHeight: '15.73px',
										textAlign: 'left',
										color: COLOR.primary,
									}}
								>
									{row.value || '-'}
								</Typography>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			{children}
		</TransactionCard>
	)
}
